/* autoprefixer grid: autoplace */
.brandedSearchPageCollection {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-column-gap: 40px;
	grid-row-gap: 40px;
	overflow-y: auto;
	padding-bottom: 15px;
}

.brandedSearchHeader {
	display: flex;
	justify-content: space-between;
}

@media (--desktop) {
	.brandedSearchPageCollection {
		grid-row-gap: 10px;
		grid-column-gap: 10px;
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* autoprefixer grid: autoplace */
	.brandedSearchPageCollection {
		grid-template-columns: 290px 290px 290px 290px;
		grid-template-rows: auto;
		grid-column-gap: 40px;
		grid-row-gap: 40px;
	}
}

@media (--desktop) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.brandedSearchPageCollection {
		grid-template-columns: 290px 290px 290px 290px;
		grid-template-rows: auto;
		grid-row-gap: 10px;
		grid-column-gap: 10px;
	}
}

@media (--mobile) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* autoprefixer grid: autoplace */
	.brandedSearchPageCollection {
		grid-template-columns: 218px 218px 218px 218px;
		grid-template-rows: auto;
		grid-column-gap: 10px;
		grid-row-gap: 10px;
	}
}
